
$(document).ready(function() {
  var $timetable = $('table.time');

  if ($timetable.length > 0) {
    var months = ["January","February","March","April","May","June","July",
                "August","September","October","November","December"];
    var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    var $timezone_selector = $('.timezone-selector');
    var user_timezone = $timezone_selector.attr('data-timezone');

    $timezone_selector.on('change', function() {
      console.log('changed')
      var timezone = $(this).val();
      $timetable.find('.timetable-time').each(function() {
        var date = $(this).attr('data-time').replace(/ /g, "T");
        if (date) {
          var selectedTime = new Date(date).toLocaleString("en-US", {timeZone: timezone});
          var d = new Date(selectedTime);
          var time_only = (d.getHours()<10?'0':'') + d.getHours() + ':' + (d.getMinutes()<10?'0':'') + d.getMinutes();
          $(this).text(time_only);
        }
      });
      $timetable.find('.day-header--time').each(function() {
        var date = $(this).attr('data-time').replace(/ /g, "T");

        if (date) {
          var selectedTime = new Date(date).toLocaleString("en-US", {timeZone: timezone});
          var d = new Date(selectedTime);
          var formatted_date = days[d.getDay()]+ ', ' + d.getDate() + ' ' + months[d.getMonth()]
          $(this).text(formatted_date);
        }
      });
    });
    if (user_timezone) {
      $timezone_selector.val(user_timezone).trigger('change');
    }

    setTimeout(function() {
      $timezone_selector.trigger('change');
    },400);

  }

});
