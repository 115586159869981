import linkifyElement from "linkifyjs/element";
import "./src/select2/js/select2.min.js"
import "./src/select2/css/select2.min.css"
import "./global.scss";
import "./timetable.scss";
import "./timetable_print.scss";
import "./ecmwf.scss";
import "./ecmwf_header.scss";
import "./ecmwf_footer.scss";
import "./iframe.scss";
import "./extra_info.scss";
import "./timezone_widget.js";

document.getElementsByTagName('head')[0].innerHTML += '<meta id="viewport" name="viewport" content="width=device-width, initial-scale=1">';


function changeReactFormValue(input,value){

  var nativeInputValueSetter = Object.getOwnPropertyDescriptor(
    window.HTMLInputElement.prototype,
    "value"
  ).set;
  nativeInputValueSetter.call(input, value);

  var inputEvent = new Event("input", { bubbles: true });
  input.dispatchEvent(inputEvent);
}

$(document).ready(function() {
  $('.select2-enable').select2();
});
// Point the abstract pdf generation link inside the "Call for Abstracts" menu
// in the management area to our custom abstract html page
$(document).ready(function() {
  var download_links = $('a[href$="book-of-abstracts.pdf"]').get();
  if (download_links.length > 0) {
    var download_link = download_links[0];
    var regex = /event\/(\d+)\/book-of-abstracts\.pdf/;
    var event_id = download_link.href.match(regex)[1];
    var new_download_url = "/ecmwf" + "/event/" + event_id + "/abstracts";
    download_link.href = new_download_url;
    download_link.target = "_blank";
  }
});

// Turn text into links inside the registration form
$(document).ready(function() {
  function linkifyForm() {
    document.querySelectorAll("span.ng-binding").forEach(el => linkifyElement(el));
  }
  // The form is rendered on the frontend and we need to wait around 2 second before the element exist
  setTimeout(linkifyForm, 2000);
});

// Google analytics
(function(w, d, s, l, i) {
  w[l] = w[l] || [];
  w[l].push({
    "gtm.start": new Date().getTime(),
    event: "gtm.js"
  });
  var f = d.getElementsByTagName(s)[0],
    j = d.createElement(s),
    dl = l != "dataLayer" ? "&l=" + l : "";
  j.async = true;
  j.src = "//www.googletagmanager.com/gtm.js?id=" + i + dl;
  f.parentNode.insertBefore(j, f);
})(window, document, "script", "dataLayer", "GTM-P5T597");

$(document).ready(function() {

  
  // Allows iframe in event description.
  
  // This is not working in indico 3.2.2
  /* if (typeof CKEDITOR !== 'undefined') {
    CKEDITOR.config.allowedContent = true;
    CKEDITOR.on('instanceCreated', function(event) {
      var editor = event.editor,
      element = editor.element;
      editor.on('afterCommandExec', function(e) {
        //If the Source command is toggled
        if ( e.data.name == 'source' ) {
          var data = editor.getData();
          data = data.replace('<p><iframe','<p class="iframe-wrapper"><iframe');
          editor.setData(data);
        }
      });
    });
  }
  // Convert iframe escaped text to iframes
  $('.iframe-wrapper').each(function() {
    var iframeCode = $(this).text();
    $('.iframe-wrapper').html($(iframeCode)).show();
  }); */
  // For Indico 3.2.2, use the raw HTML instead.
  $('.raw-html-embed').each(function() {
    $(this).hide();
    const rawHTML = $(this).text();
    if (rawHTML.includes('iframe')) {
      const iframeEmbed = $('<div />', {
        'class': 'iframe-wrapper',
        html: rawHTML
      });
      $(this).after(iframeEmbed);
      iframeEmbed.show();
    }
  });


  //Sets and dipslays extra information field on categories
  //Adds feld to management settings form

  var ecmwf_encodeString = function(s) {
    s = s.replace(/</g,'{{');
    return s.replace(/>/g,'}}');
  }

  var ecmwf_decodeString = function(s) {
    s = s.replace(/{{/g,'<');
    return s.replace(/}}/g,'>');
  }

  if($('#side-menu-category-management-sidemenu').length > 0) {

    var $description_form_group = $('.management-page #form-group-description');
    var $description_textarea = $description_form_group.find('textarea');
    var extra_info_current = '';
    var description_html = $('<div/>').append($description_textarea.val());


    if (description_html.find('code').length > 0) {

      description_html.find('code').each(function() {
        extra_info_current = extra_info_current + ecmwf_decodeString($(this).text());

      });
      description_html.find('code').each(function() {
        $(this).remove();
      });
      $description_textarea.val(description_html[0].innerHTML);

    }

    var $extra_info_form_group = $('<div/>', {'class': 'form-group', id: 'form-group-extra-info'})
    .append($('<div/>',{'class': 'form-label'}).append('<label for="extra-info">Extra information</label>'))
    .append($('<div/>',{'class': 'form-field'}).append('<textarea id="extra-info" name="extra-info" rows="5" spellcheck="false">'+extra_info_current+'</textarea>'))
    .insertAfter('.management-page #form-group-description')

    var $form = $extra_info_form_group.closest('form')
    .one('submit', function(e) {
      e.preventDefault();
      if (typeof CKEDITOR !== 'undefined' && 'extra-info' in CKEDITOR.instances) {
        CKEDITOR.instances['extra-info'].updateElement();
      }
      var extra_info_text = $extra_info_form_group.find('textarea').val();

      if (extra_info_text && extra_info_text != '') {

        var edited_description = $description_textarea.val() + '<code>' + ecmwf_encodeString(extra_info_text) + '</code>';
        $description_textarea.val(edited_description);
      }
      $form.submit();
    })

    if (typeof CKEDITOR !== 'undefined') {
      CKEDITOR.replace('extra-info');
      CKEDITOR.instances['extra-info'].on('change', function() {
        $('input[type="submit"]', $form).removeAttr('disabled');
      });
    }
  }

  if ($('.container-category').length > 0 || $('.category-container').length > 0) {
    var extra_info_html = '';
    $('.field-name-body code').add('.category-description code').each(function() {
      extra_info_html += ecmwf_decodeString($(this).text());
    })
    $('.region-sidebar-second').add('.category-sidebar').find('.extra-info').html(extra_info_html);
  }
});

// Set Country Select programmatically, based on Country select field.
$(document).on('focus', 'div[id*="registration-form"]', function() {
  var $regform = $(this);
  var reg_data = $regform.data('form-data');
  var event_id = $regform.data('event-id');
  var $country_status_input = null;
  var $country_status_field = null;
  $regform.find('label').each(function() {
    if ($(this).text().toLowerCase().trim() == 'country status') {
      $country_status_field = $(this).closest('div.field');
      $country_status_input = $country_status_field.find('input');
    }
  });

  if ($country_status_field && $country_status_input && $country_status_input.length > 0) {

      //Hide the field to users
      $country_status_field.hide();
      var $country_selector = $('div[class*="country-dropdown"]');

      if ($country_selector.length > 0) {
        var observer = new MutationObserver(function(mutations) {
          mutations.forEach(function(mutation) {
            var attributeValue = $(mutation.target).prop(mutation.attributeName);
            if (!attributeValue.includes('active')) {
              var country_name = $country_selector.find('.divider.text').text()
              .replace(
                /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
                ''
              )
              .replace(/\s+/g, ' ')
              .trim();
              
              // Extract country code from Country Select field.
              var country_code = null;
              if ('items' in reg_data) {
                for (var i in reg_data.items) {
                  var inputType = reg_data.items[i].inputType;
                  if (inputType == 'country' && 'choices' in reg_data.items[i]) {
                    for (var c in reg_data.items[i].choices) {
                      var countryItem = reg_data.items[i].choices[c]
                      if (countryItem.caption == country_name) {
                        country_code = countryItem.countryKey;
                        break;
                      }
                    }
                    break;
                  }
                }
              }
              
              // Get Country Status and populate Country Status field.
              $.ajax({
                url: '/event/'+event_id+'/manage/get-country-status',
                data: {'country_code': country_code},
                success: function(d) {
                  if ('country_status' in d) {
                    changeReactFormValue($country_status_input[0], d['country_status'])
                  }
                }
              });
            }
          });
        });

        // Use the oberver to detect Country select changes.
        observer.observe($country_selector[0], {
          attributes: true,
          attributeFilter: ['class']
        });
      }
  }
});

$(document).ready(function() {
  $('.navbar-toggle').on('click', function() {
      var id = $(this).attr('id');
      var target = $(this).data('target');
      $(this).toggleClass('active');
      $('.navbar-toggle:not(#' + id + ')').removeClass('active');
      if (target == '#navbar-user' && !IndicoGlobalVars.isUserAuthenticated) {
        window.location = $(this).attr('data-url');
      } else {
        $('.navbar-target').not(target).slideUp();
        $(target).slideToggle('slow');

        if ($(this).hasClass('active')) {
          $('.menu-collapse-overlay').fadeIn(200);
          $('.white-line').addClass('white')
        } else {
            $('.menu-collapse-overlay').fadeOut(200);
            $('.white-line').delay(600).removeClass('white');
        }
      }

  });
});

// ECMWF header and realted behaviours
$(document).ready(function() {

  //Similate search form submission
  var search_submit_btn = $('.ecmwf_header #navbar-search button[type="submit"]');
  var search_entry_field = $('.ecmwf_header #navbar-search .input-group input')
  .keypress(function(event){
    var keycode = (event.keyCode ? event.keyCode : event.which);
    if(keycode == '13'){
      search_submit_btn.trigger('click');
    }
  });
  search_submit_btn.on('click', function() {
    var search_entry = search_entry_field.val();
    if (search_entry && search_entry.trim() != '') {
      window.location = 'https://www.ecmwf.int/search/site/' + search_entry;
    }
  });

  // Adds items to user form
  var user_menu = $('#user-settings-widget');
  if (user_menu.length > 0) {
    var language_switcher = $('#language-selector-link', user_menu).closest('.settingsWidgetSection');
    if (Indico.User && Indico.User.isAdmin == true) {
      var admin_link = $('<a id="admin" class="admin" data-href="/admin/">Administration</a>').on('click', function(){
        window.location = $(this).attr('data-href');
      });
      var admin_link_wrapper = $('<div class="settingsWidgetSection"></div>').append(admin_link);
      admin_link_wrapper.insertAfter(language_switcher);
    }

    var create_event_link = $('#create-event-link');
    if (create_event_link.length > 0) {
      var create_link_wrapper = $('<div class="settingsWidgetSection">'+create_event_link.html()+'</div>')
      .insertAfter(language_switcher);
    }
  }
});

// ECMWF footer behaviours
$(document).ready(function() {
  var footer_manu_list_items = $('footer #site-footer-menu .menu-name-main-menu > ul.menu.nav > li');
  var applyEqualHeight = function() {
    var h = 0;
    footer_manu_list_items.each( function() {
      $(this).css({'height': 'auto'});
      var el_h = $(this).height();
      if (el_h > h) {
        h = el_h;
      }
    });
    footer_manu_list_items.height(h);
  }
  $(window).on('resize', function() {
    applyEqualHeight();
  }).trigger('resize');
});

$(document).ready(function() {
  // ECMWF login and SSo behaviour
  if (window.location.pathname.match(/\/user\/[0-9]*\/profile\//)) {
    if (Indico.User && Indico.User.isAdmin == false) {
      $("input#first_name").add("input#last_name").attr('disabled','disabled');
    }
  }
  if (window.location.pathname.match(/\/user\/[0-9]*\/emails\//)) {
    $("#form-group-email").add(".form-group-footer").hide();
  }
  if (window.location.pathname.match(/\/user\/[0-9]*\/accounts\//)) {
    $("input#username").add("input#password")
    .add("input#password").add("input#new_password").add("input#confirm_new_password")
    .attr('disabled','disabled');
    $(".page-content").hide();
  }
  if (window.location.pathname.match(/\/user\/[0-9]*\/\w*\//)) {
    $(".side-menu ul.menu-items").find("li").each(function() {
      var $link = $(this).find('> a > span');
      if ($link.length > 0 && $link.text() == 'Accounts') {
        $(this).hide();
      }
    });
  }
  $("#indico-user-to-add").closest('.group').find("ul.dropdown >li").each(function(i,v) {
    switch (i) {
      case 0:
        $(this).find("a")
        .attr('href','https://apps.ecmwf.int/registration/')
        .attr('target','_blank')
        break;
    }
  })
  // Hide Seminar link (lecture template)
  $('a.lecture-category[href="/category/9/"]').hide();
});
